<template>
    <LayoutNext>
        <template #page-title>
            Users
        </template>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="d-md-flex d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-start mb-3 mb-md-0 align-items-center">
                    <router-link :to="{ name: 'AddUser' }" custom v-slot="{ href, navigate }">
                        <MDBBtn color="primary" @click="navigate" :href="href" class="fw-bold px-3 py-2" size="sm">
                            <MDBIcon iconStyle="fas" icon="user-plus" class="me-1" />
                            Add
                        </MDBBtn>
                    </router-link>
                </div>
                <div class="d-block d-md-flex justify-content-end filter-container">
                    <div class="did-floating-label-content accountStatus-width me-0 me-md-2 my-2 my-md-0">
                        <select v-model="filterByAccountStatus" class="did-floating-select">
                            <option v-for="option in accountStatusFilter" :key="option.value" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <label class="did-floating-label">Filter by Account Status</label>
                    </div>
                    <div class="did-floating-label-content userType-width me-0 me-md-2 my-2 my-md-0">
                        <select v-model="selectedUserTypeFilter" class="did-floating-select">
                            <option v-for="option in userTypeFilter" :key="option.value" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <label class="did-floating-label">Filter by User Type</label>
                    </div>
                    <ClientsSearchNext :searchType="'userManagement'" @searchResult="userManagementList = $event" />
                    <MDBDropdown v-model="dropdown1" class="ms-md-2 mt-2 mt-md-0">
                        <MDBDropdownToggle class="fw-bold" @click="dropdown1 = !dropdown1">
                            Sort by
                        </MDBDropdownToggle>
                        <MDBDropdownMenu aria-labelledby="dropdownMenuButton" style="z-index: 1050;">
                            <MDBDropdownItem tag="button" @click="toggleSortOrder">
                                <MDBIcon class="me-1" size="sm" iconStyle="fas"
                                    :icon="sortOrder === 'asc' ? 'arrow-up' : 'arrow-down'" />
                                Join Date
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div class="overflow-auto mt-3" id="table-container">
                    <MDBTable hover class="align-middle">
                        <thead class="table-light">
                            <tr>
                                <th class="sticky-top">Name</th>
                                <th class="sticky-top">Email</th>
                                <th class="sticky-top">CS Code</th>
                                <th class="sticky-top">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="loadingSpinner">
                                <tr>
                                    <td colspan="4" class="text-center">
                                        <div class="image-container d-flex justify-content-center align-items-center">
                                            <div class="loader"></div>
                                            <img src="@/assets/cs-loader.png" alt="Loading image">
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="userManagementList.data.length === 0 && !loadingSpinner">
                                    <td colspan="4" class="text-center">No user found.</td>
                                </tr>
                                <template v-else v-for="(item, i) in userManagementList.data" :key="i">
                                    <ClientsTableRowNext :item="item" @onAction="getUsers" />
                                </template>
                            </template>
                        </tbody>
                    </MDBTable>
                </div>
                <div class="overflow-auto">
                    <Paginate v-if="userManagementList.pagination.totalPages"
                        :page-count="userManagementList.pagination.totalPages" :pageType="'userManagement'"
                        @paginationData="userManagementList = $event" @paginationLoading="loadingSpinner = $event" />
                </div>
            </MDBCardBody>
        </MDBCard>
        <DeductionsToast />
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBIcon, MDBTable, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { useTitle } from "@vueuse/core";
import DeductionsToast from "@/components/Deductions/DeductionsToast.vue";
import { useToastStore } from "@/store/toastStore";
import { onMounted, ref, watch } from "vue";
import axios from "axios";
import ClientsSearchNext from "./ClientsSearchNext.vue";
import ClientsTableRowNext from "./ClientsTableRowNext.vue";
import Paginate from "@/components/Pagination/CSDPagination.vue";

useTitle("Users | CreatorShield");

const toastStore = useToastStore();
const dropdown1 = ref(false);

const userManagementList = ref({
    data: [],
    pagination: {},
});

const selectedUserTypeFilter = ref(null);
const userTypeFilter = ref([
    { value: null, text: "All" },
    { value: 1, text: "Admin" },
    { value: 2, text: "User" },
    { value: 3, text: "Distributor" },
    { value: 4, text: "Super Admin" },
]);

const filterByAccountStatus = ref(null);
const accountStatusFilter = ref([
    { value: null, text: "All" },
    { value: true, text: "Active" },
    { value: false, text: "Inactive" },
]);

const sortOrder = ref('asc');
const toggleSortOrder = () => {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    getUsers();
};

const loadingSpinner = ref(false);
const getUsers = async () => {
    loadingSpinner.value = true;
    const params = {};

    if (selectedUserTypeFilter.value) {
        params.userTypeId = selectedUserTypeFilter.value;
    }
    if (filterByAccountStatus.value !== null) {
        params.isActive = filterByAccountStatus.value;
    }

    params.sortBy = 'activationDate';
    params.sortOrder = sortOrder.value;

    try {
        const response = await axios.get("api/users", { params });
        userManagementList.value.data = response.data.data;
        userManagementList.value.pagination = response.data.pagination;
        loadingSpinner.value = false;
    } catch (error) {
        console.error("Error fetching users: ", error);
        toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
        };
        loadingSpinner.value = false;
    }
};

onMounted(() => {
    getUsers();
});

watch([selectedUserTypeFilter, filterByAccountStatus], () => {
    getUsers();
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.table-dark {
    --mdb-table-bg: var(--accent);
    border-color: var(--accent);
}
</style>

<style>
.user-management-loader {
    color: var(--accent);
}

th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 55vh;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* customized select with floating label */
.did-floating-label-content {
    position: relative;
}

.did-floating-label {
    color: #1e4c82;
    font-size: 15px;
    position: absolute;
    pointer-events: none;
    left: 8.2px;
    top: 11px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
    font-size: 13px;
    display: block;
    width: 100%;
    height: 34px;
    padding: 8px 13px;
    padding-right: 19px;
    background: #fff;
    color: #6c757d;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: 1.5px solid #0d6efd;

        ~.did-floating-label {
            color: #0d6efd;
            top: -8px;
            font-size: 11px;
        }
    }
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.did-floating-select::-ms-expand {
    display: none;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
    top: -7.5px;
    font-size: 11px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
    top: -7.5px;
    font-size: 11px;
}

.did-floating-select[value=""]:focus~.did-floating-label {
    top: 11px;
    font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.userType-width {
    width: 65%;
}

.accountStatus-width {
    width: 75%;
}

.filter-container {
    width: 60%;
}

@media (max-width: 767px) {
    .userType-width {
        width: 100%;
    }

    .accountStatus-width {
        width: 100%;
    }

    .filter-container {
        width: 100%;
    }
}
</style>
