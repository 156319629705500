<template>
  <Paginate
    class="overflow-auto"
    :page-count="paginationData.totalPages"
    :click-handler="handleClick"
    :prev-text="'Previous'"
    :next-text="'Next'"
    :container-class="'pagination'"
    first-last-button
  ></Paginate>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";
import { ref, onBeforeMount, computed } from "vue";

export default {
  components: {
    Paginate,
  },
  emits: ["paginationData", "paginationLoading"],
  props: ["pageType", "assetLabelId"],
  setup(props, ctx) {
    const list = ref([]);
    const paginationData = ref({});
    const pageTypeURL = computed(() => {
      if (props.pageType == "assetLabel") {
        return "api/assetlabels/List";
      } else if (props.pageType == "assets") {
        return "api/assets/List";
      } else if (props.pageType == "userManagement") {
        return "api/users";
      } else if (props.pageType == "revenue") {
        return "api/revenues/List";
      } else if (props.pageType == "deductions") {
        return "api/assetRevenueDeductions";
      } else if (props.pageType == "notifManagementList") {
        return "api/creatorvideoupdates/list";
      } else if (props.pageType == "notificationList") {
        return "api/notifications/list";
      } else if (props.assetLabelId && props.pageType == "assetLabelAssets") {
        return `api/assetlabels/${props.assetLabelId}/Assets`;
      }
      return "";
    });
    const getDataFromAPI = (currentPage, searchString) => {
      ctx.emit("paginationLoading", true);
      axios
        .get(pageTypeURL.value, {
          params: {
            PageNumber: currentPage,
            ...(searchString ? { SearchString: searchString } : {}),
          },
        })
        .then(function (response) {
          list.value = response.data.data;
          paginationData.value = response.data.pagination;
          ctx.emit("paginationData", {
            data: list,
            pagination: paginationData,
          });
          ctx.emit("paginationLoading", false);
        });
    };
    onBeforeMount(getDataFromAPI);
    const handleClick = (currentPage) => {
      getDataFromAPI(currentPage);
    };
    return {
      handleClick,
      paginationData,
      getDataFromAPI,
      list,
      pageTypeURL,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 1rem;
  margin-left: auto;
  cursor: default;
}
</style>
